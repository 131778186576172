import React, { Component } from 'react';
import "./partnerview.scss"
import 'animate.css';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class PartnerView extends Component {
    render(){
        return (
            <div class="container animate__animated animate__fadeIn">
                <h2>Reduce store <span style={{color:"#71C562"}}>shrink</span>.</h2>
                <h2>Attract more customers.</h2>
                <h2>Surpass your sustainability goals.</h2>
                <p>Connect with our team to discuss how Munch More can reduce waste in your stores.</p>
                <div class="icon-group"><FontAwesomeIcon class="icon" icon={faEnvelope} /><span>munchmorecontact@gmail.com</span></div>
            </div>
        );
    }
}

export default PartnerView;