import React, { Component } from 'react';
import "./joinview.scss"
import Card from "../../components/card/Card"
import Helmet from "react-helmet"

// update data-waitlist_id // 

class JoinView extends Component {
    render(){
        return (
            <div class="container">
                <div class="row landing-page-row">
                    <div class="col-12 col-md-6 animate__animated animate__fadeIn">
                        <h3>Join the <span style={{color:"#71C562"}}>waitlist.</span> <br></br>Become the solution to eliminating food waste</h3>
                        <p>Help us reshape the idea around food waste.</p>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <Card>
                                <div id="getWaitlistContainer" data-waitlist_id="10436" data-widget_type="WIDGET_1"></div> 
                            </Card>
                            <Helmet><link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
                                <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script></Helmet>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JoinView;