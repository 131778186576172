import React, { Component } from 'react';
import "./homeview.scss"
import 'animate.css';
import landingImage from "./assets/appView.png";

class HomeView extends Component {
    render(){
        return (
            <div class="container">
                <div class="row landing-page-row">
                    <div class="col-12 col-md-6 animate__animated animate__fadeIn">
                        <h1>Got the late night  <span style={{color:"#D2691E"}}>munchies</span>? </h1>
                        <p> Join us in the fight against food <span style={{color:"#71C562"}}> waste</span> while 
                        getting massive savings on surplus snacks that would otherwise be thrown away. <br/><br/>
                        Spread joy by knowing that every box purchased is a box donated to our local first responders.</p>
                        <a href="/join" class="button">Join Waitlist</a>
                    </div>
                    <div class="col-12 col-md-6 hide-mobile">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <img src={landingImage}></img>
                        </div>
                    </div>
                </div>
                <div class="container animate__animated animate__fadeIn">
                    <h1>How it works.</h1>
                    <p>First, use the Free Munch More Web app to quickly and easily find discounted food from wherever you are.</p>
                    
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="card animate__animated animate__fadeIn">
                                <p>Browse our Munch More Mystery Boxes.</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                        <div class="card animate__animated animate__fadeIn">
                                <p>Choose from a variety of discounted food items.</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                        <div class="card animate__animated animate__fadeIn">
                                <p>Pay right in the app using any major credit card, VISA debit card.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeView;