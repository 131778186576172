import React, { Component } from 'react';
import "./nopage.scss"

class NoPage extends Component {
    render(){
        return (
            <div class="container">
                <h1>Error <span style={{color:"#71C562"}}>404</span></h1>
                <p>No food waste saving here :(...</p>
            </div>
        );
    }
}

export default NoPage;