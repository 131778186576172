import logo from './logo.svg';
import './App.scss';
import Nav from "./components/nav/Nav"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeView from './views/Home/Home'
import NoPage from './views/NoPage/NoPage'
import PartnerView from './views/Partner/PartnerView'
import AboutView from './views/About/About';
import JoinView from './views/Join/Join';

function App() {
  return (
    <div className="App">
      <Nav/>
      
      <div className="main-body">
        <BrowserRouter>
          <Routes>
            <Route>
              <Route index element={<HomeView />} />
              <Route path="partner" element={<PartnerView />} />
              <Route path="about" element={<AboutView />} />
              <Route path="join" element={<JoinView />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
