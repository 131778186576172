import React, { Component } from 'react';
import './nav.scss';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
      isLogoTransparent: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 64) {
      this.setState({ isLogoTransparent: true });
    } else {
      this.setState({ isLogoTransparent: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  render() {
    const { isMobileMenuOpen, isLogoTransparent } = this.state;

    return (
      <nav>
        <a className={`logo ${isLogoTransparent ? 'transparent' : ''}`} href="/">
          <span style={{color:"#D2691E"}}>Munch More</span>
        </a>
        <div className={`nav-links-container ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li>
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link" href="/partner">
                Partner
              </a>
            </li>
            <li>
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li>
              <a className="nav-link" href="/join">
                Join
              </a>
            </li>
          </ul>
          <a className="button" href="/join">
            Get Started
          </a>
          <div className={`mobile-menu-burger ${isMobileMenuOpen ? 'open' : ''}`} onClick={this.toggleMobileMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Nav;
