import React, { Component } from 'react';
import "./aboutview.scss"

class AboutView extends Component {
    render(){
        return (
            <div class="container animate__animated animate__fadeIn">
                <div class="d-flex flex-column text-align-center justify-content-center align-items-center">
                    <h3>A marketplace that connects surplus food with hungry customers</h3>
                    <p>Munch More is a marketplace where you can purchase discounted items from your favorite Grocery stores, Restaurants, and bakeries all while saving food.</p>
                </div>
            </div>
        );
    }
}

export default AboutView;